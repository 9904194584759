import React, { forwardRef, useEffect, useMemo, useRef } from 'react'
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber'
import { Html } from '@react-three/drei'
import * as THREE from 'three'
import { TextureLoader } from 'three'
import useDisplacement from '../hooks/useDisplacement.js'
import ScramblingText from './ScramblingText.jsx'
import particlesVertexShader from '../shaders/particles/vertex.glsl'
import particlesFragmentShader from '../shaders/particles/fragment.glsl'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const createParticlesGeometry = () => {
  const geometry = new THREE.PlaneGeometry(8, 8, 128, 128)
  geometry.setIndex(null)
  geometry.deleteAttribute('normal')

  const initialPositions = []
  const positions = geometry.attributes.position.array
  const dispersionFactor = 8

  for (let i = 0; i < positions.length; i += 3) {
    initialPositions.push(positions[i] + (Math.random() - 0.5) * dispersionFactor)
    initialPositions.push(positions[i + 1] + (Math.random() - 0.5) * dispersionFactor)
    initialPositions.push(positions[i + 2] + (Math.random() - 0.5) * dispersionFactor)
  }

  geometry.setAttribute('aInitialPosition', new THREE.BufferAttribute(new Float32Array(initialPositions), 3))

  const intensitiesArray = new Float32Array(geometry.attributes.position.count)
  const anglesArray = new Float32Array(geometry.attributes.position.count)

  for (let i = 0; i < geometry.attributes.position.count; i++) {
    intensitiesArray[i] = Math.random()
    anglesArray[i] = Math.random() * Math.PI * 2
  }

  geometry.setAttribute('aIntensity', new THREE.BufferAttribute(intensitiesArray, 1))
  geometry.setAttribute('aAngle', new THREE.BufferAttribute(anglesArray, 1))

  return geometry
}

const Particles = forwardRef((_, ref) => {
  const clock = useRef(new THREE.Clock())
  const isVisible = useRef(false)
  const { size, viewport, scene, camera } = useThree()
  const texture = useLoader(TextureLoader, '../images/picture.png')

  const { loading: displacementLoading, displacement } = useDisplacement(scene)

  const particlesGeometry = useMemo(() => createParticlesGeometry(), [])

  const particlesMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      vertexShader: particlesVertexShader,
      fragmentShader: particlesFragmentShader,
      uniforms: {
        uResolution: { value: new THREE.Vector2(size.width * viewport.dpr, size.height * viewport.dpr) },
        uPictureTexture: { value: texture },
        uDisplacementTexture: { value: displacement?.texture || null },
        uTime: { value: 0 }
      }
    })
  }, [displacement, size, viewport])

  useFrame(() => {
    if (displacementLoading) return

    if (isVisible.current && particlesMaterial) {
      particlesMaterial.uniforms.uTime.value = clock.current.getElapsedTime() / 5
    }

    if (displacement.raycaster && displacement.interactivePlane) {
      displacement.raycaster.setFromCamera(displacement.screenCursor, camera)
      const intersections = displacement.raycaster.intersectObject(displacement.interactivePlane)
      if (intersections.length) {
        const uv = intersections[0].uv
        displacement.canvasCursor.x = uv.x * displacement.canvas.width
        displacement.canvasCursor.y = (1 - uv.y) * displacement.canvas.height
      }
    }

    displacement.context.globalCompositeOperation = 'source-over'
    displacement.context.globalAlpha = 0.02
    displacement.context.fillRect(0, 0, displacement.canvas.width, displacement.canvas.height)

    const cursorDistance = displacement.canvasCursorPrevious.distanceTo(displacement.canvasCursor)
    displacement.canvasCursorPrevious.copy(displacement.canvasCursor)
    const alpha = Math.min(cursorDistance * 0.2, 1)

    const glowSize = displacement.canvas.width * 0.10
    displacement.context.globalCompositeOperation = 'lighten'
    displacement.context.globalAlpha = alpha
    displacement.context.drawImage(
      displacement.glowImage,
      displacement.canvasCursor.x - glowSize * 0.5,
      displacement.canvasCursor.y - glowSize * 0.5,
      glowSize,
      glowSize
    )

    displacement.texture.needsUpdate = true
  })

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        isVisible.current = entry.isIntersecting
        if (isVisible.current) {
          clock.current.start()
        } else {
          clock.current.stop()
        }
      },
      { threshold: 0.001 }
    )

    if (ref?.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref?.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref])

  return <points geometry={particlesGeometry} material={particlesMaterial} />
})

export default function ImageParticles() {
  const triggerRef = React.useRef(null)
  const words = [
    'idea dynamo', 'ambition aficionado', 'challenge champion', 'adventure artisan',
    'passion enthusiast', 'gelato guru', 'focus ninja', 'dream chaser',
    'tech whisperer', 'cookie monster', 'brainstorming wizard'
  ]

  return (
    <Canvas ref={triggerRef} camera={{ fov: 35, near: 0.1, far: 100, position: [0, 0, 18] }}>
      <Particles ref={triggerRef} />
      <Html position={[0, 0, 0]} center>
        <div className="flex flex-col">
          <div className="skills__text">
            <ScramblingText words={words} />
          </div>
        </div>
      </Html>
    </Canvas>
  )
}

import { useGLTF } from '@react-three/drei'
import React from 'react'
import Model from '../../assets/models/headphones.glb';
import Mesh from './Mesh.jsx'

export default function Headphones({ mouse }) {
  const { nodes, materials } = useGLTF(Model)
  const position = window.innerWidth <= 768 ? [0.8, 3.22, -6] : [1.8, 3.22, -6]
  const scale = window.innerWidth <= 768 ? 0.35 : 0.4

  return (
    <group scale={scale} rotation={[1, -0.3, 0]} position={position}>
      <Mesh multiplier={1.8} mouse={mouse} node={nodes.curve2___bli_0} />
      <Mesh multiplier={1.8} mouse={mouse} node={nodes.curve2_bli_0} />
      <Mesh multiplier={1.8} mouse={mouse} node={nodes['curve2_��������t4_0']} />
      <Mesh multiplier={1.8} mouse={mouse} node={nodes.curve2_lambert1_0} />
      <Mesh multiplier={1.8} mouse={mouse} node={nodes.curve2_t3_0} />
      <Mesh multiplier={1.8} mouse={mouse} node={nodes.curve2_lambert2_0} />
    </group>
  )
}

useGLTF.preload(Model)

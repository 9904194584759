import { useGLTF } from '@react-three/drei'
import React from 'react'
import Model from '../../assets/models/keyboard.glb'
import Mesh from './Mesh.jsx'

export default function Keyboard({ mouse }) {
  const { nodes, materials } = useGLTF(Model)
  const position = window.innerWidth <= 768 ? [0.8, -0.4, 0] : [4.8, -1.4, 0]
  const scale = window.innerWidth <= 768 ? 7 : 10

  return (
    <group scale={scale} position={position} rotation={[2, -1, 1]}>
      <Mesh multiplier={1.4} mouse={mouse} node={nodes.Object_4} />
      <Mesh multiplier={1.4} mouse={mouse} node={nodes.Object_5} />
      <Mesh multiplier={1.4} mouse={mouse} node={nodes.Object_6} />
      <Mesh multiplier={1.4} mouse={mouse} node={nodes.Object_7} />
      <Mesh multiplier={1.4} mouse={mouse} node={nodes.Object_8} />
      <Mesh multiplier={1.4} mouse={mouse} node={nodes.Object_9} />
      <Mesh multiplier={1.4} mouse={mouse} node={nodes.Object_10} />
    </group>
  )
}

useGLTF.preload(Model)

import React, { Suspense, useEffect, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { Environment, useGLTF } from '@react-three/drei'
import { useTransform } from 'framer-motion'
import { motion } from 'framer-motion-3d'
import { gsap } from 'gsap'
import Model from '../assets/models/elephant_head_low_poly_stylized.glb'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

function Elephant({ mouse, multiplier }) {
  const { nodes } = useGLTF(Model)
  const node = nodes['Sphere001_07_-_Default_0']
  const { geometry, rotation } = node

  const meshRef = useRef()

  const rotationX = window.innerWidth > 768 ? useTransform(mouse.x, [0,1], [rotation.x - multiplier, rotation.x + multiplier]) : rotation.x
  const rotationY = window.innerWidth > 768 ? useTransform(mouse.y, [0,1], [rotation.y - multiplier, rotation.y + multiplier]) : rotation.y

  useEffect(() => {
    if (!meshRef.current?.scale) {
      return
    }

    if (window.innerWidth > 768) {
      gsap.fromTo(meshRef.current.scale,
        { x: 0, y: 0, z: 0 },
        {
          x: 0.04,
          y: 0.04,
          z: 0.04,
          duration: 2,
          delay: 1.2,
          ease: 'power2.out'
        }
      )
    }

    if (window.innerWidth <= 768) {
      gsap.fromTo(meshRef.current.scale,
        { x: 0, y: 0, z: 0 },
        {
          x: 0.03,
          y: 0.03,
          z: 0.03,
          duration: 1,
          delay: 0,
          ease: 'power2.out'
        }
      )
    }

    ScrollTrigger.create({
      trigger: "#about-me",
      start: "top 100%",
      end: "top 100px",
      scrub: true,
      duration: 3,
      onUpdate: (self) => {
        if (!meshRef.current?.scale) {
          return
        }

        const scaleValue = window.innerWidth <= 768 ? 0.03 * (1 - self.progress) : 0.04 * (1 - self.progress)
        gsap.to(meshRef.current.scale, {
          x: scaleValue,
          y: scaleValue,
          z: scaleValue,
          overwrite: true,
          ease: 'none'
        })
      },
    })
  }, [])

  return (
      <motion.mesh
        ref={meshRef}
        castShadow
        receiveShadow
        geometry={geometry}
        position={[0, 0.5, 0]}
        rotation-y={rotationX}
        rotation-x={rotationY}
      >
        <meshStandardMaterial
          color={0xffffff}
          roughness={0}
          metalness={1}
        />
      </motion.mesh>
  )
}

function Scene({ mouse, multiplier }) {

  return (
    <group>
      <group>
        <pointLight color={0x85ccb8} intensity={7.5} distance={20} position={[0, 3, 2]} />
        <pointLight color={0x9f85cc} intensity={7.5} distance={20} position={[0, 3, 2]} />
      </group>
      <Elephant mouse={mouse} multiplier={multiplier} />
    </group>
  )
}

export default function HeroScene({ mouse, multiplier }) {

  return (
    <Canvas
      camera={{ position: [0, 0, 10], fov: 45 }}
      dpr={window.devicePixelRatio}
    >
      <Suspense fallback={null}>
        <Environment files="/hdr/gradient_min_26.hdr" />
        <Scene mouse={mouse} multiplier={multiplier} />
      </Suspense>
    </Canvas>
  )
}

useGLTF.preload(Model)

import { useGLTF } from '@react-three/drei'
import React from 'react'
import Model from '../../assets/models/coffee_cup.glb'
import Mesh from './Mesh.jsx'

export default function CoffeeCup({ mouse }) {
  const { nodes, materials } = useGLTF(Model)
  const position = window.innerWidth <= 768 ? [1, -2.2, 2] : [1, -2.2, 2]
  const scale = window.innerWidth <= 768 ? 0.35 : 0.4

  return (
    <group scale={scale} position={position} rotation={[-4.5, 2.5, 0]}>
      <Mesh multiplier={0.9} mouse={mouse} node={nodes.Object_2} />
      <Mesh multiplier={0.9} mouse={mouse} node={nodes.Object_3} />
      <Mesh multiplier={0.9} mouse={mouse} node={nodes.Object_4} />
    </group>
  )
}

useGLTF.preload(Model)
